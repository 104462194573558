<template>
    <div v-if="columnData" class="d-flex align-items-center text-nowrap">
        <div class="rating-section" @click="function () { if (columnData.interest_level && columnData.permissions.indexOf('tracker-review-list') >= 0) $emit('action', {action: 'show-reviews', rowId: rowId})}">
            <div class="rating">
                <span :class="{'rating__current': columnData.interest_level >= 1}"></span>
                <span :class="{'rating__current': columnData.interest_level >= 2}"></span>
                <span :class="{'rating__current': columnData.interest_level >= 3}"></span>
                <span :class="{'rating__current': columnData.interest_level >= 4}"></span>
                <span :class="{'rating__current': columnData.interest_level >= 5}"></span>
            </div>
            <span class="rating__result" v-if="columnData.reviews_count">{{columnData.reviews_count}}</span>
        </div>

        <a v-if="columnData.user_has_review && columnData.permissions.indexOf('tracker-review-own-edit') >= 0" class="link ml-3" @click="$emit('action', {action: 'edit-review', rowId: rowId})">Edit</a>
        <a v-if="!columnData.user_has_review && columnData.permissions.indexOf('tracker-review-add') >= 0" class="link ml-3" @click="$emit('action', {action: 'edit-review', rowId: rowId})">+ Add</a>
    </div>
</template>

<script>
export default {
    props: {
        columnData: {
            'reviews_count': Number,
            'interest_level': Number,
            'user_has_review': Boolean,
            'permissions': Boolean,
        },
        rowId: Number,
    },
}
</script>

<style scoped>

</style>